import React from "react"
import Layout from "../Layout"
import { Dogs } from "../components/rings/Dogs"
import { useStaticQuery, graphql } from "gatsby"
import { Seo } from "../components/Seo"
export default function HappyHounds() {
  const dogData = useStaticQuery(graphql`
    query Dogs {
      allSanityDog(sort: { fields: insta }) {
        nodes {
          name
          birthDate
          _rawMessage
          breed
          insta
          image {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }

      # allInstaNode(sort: { fields: timestamp }) {
      #   edges {
      #     node {
      #       id
      #       likes
      #       comments
      #       mediaType
      #       original
      #       timestamp
      #       caption
      #       dimensions {
      #         height
      #         width
      #       }
      #       preview
      #     }
      #   }
      # }
    }
  `)

  return (
    <Layout>
      <Seo urlLoc="https://www.onehappyhound.be/happy-hounds" />
      {dogData && dogData.allSanityDog && <Dogs dogs={dogData.allSanityDog} />}
      {/* {dogData && dogData.allInstaNode.edges && (
        <InstagramScraper posts={dogData.allInstaNode.edges} />
      )} */}
    </Layout>
  )
}
